import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { XIcon } from '@heroicons/react/solid';
import Button from 'metrics/components/Common/Button/Button';
import mergeClasses from 'metrics/utils/TailwindMergeClasses';

const DEFAULT_IMAGE_PATH = '/metricsAssets/logos/logo-grey.png';

interface IFileUpload {
    defaultImage?: string;
    accept?: string;
    onImageLoaded?(path: any): void;
    onFileSelected?(file: any): void;
    type: 'image' | 'video';
    helpText?: string;
    uploadText?: string;
    imageWrapperClassName?: string,
    buttonWrapperClassName?: string,
}
const FileUpload = (props: IFileUpload) => {
    const { t } = useTranslation();
    const inputFile = useRef(null);
    const [imgPath, setImgPath]: any = useState('');
    const [imgName, setImgName]: any = useState(undefined);
    const {
        onImageLoaded,
        onFileSelected,
        type,
        defaultImage = DEFAULT_IMAGE_PATH,
        accept = 'image/x-png,image/gif,image/jpeg',
        helpText = '',
        uploadText = '',
        imageWrapperClassName = '',
        buttonWrapperClassName = '',
    } = props;

    const onClickBtnImg = () => {
        if (inputFile.current) {
            const input: any = inputFile.current;
            input.click();
        }
    };

    const onInputImgChange = (e: any) => {
        const img = e.target.files[0];
        if (img) {
            if (onFileSelected) {
                onFileSelected(img);
            }
            setImgName(img.name);

            const reader = new FileReader();
            reader.readAsDataURL(img);
            reader.onload = () => {
                setImgPath(reader.result);
                if (onImageLoaded) {
                    onImageLoaded(reader.result);
                }
            };
            reader.onerror = function(error) {
                console.log('Error: ', error);
            };
        }
    };

    const onClickRemove = () => {
        setImgName(undefined);
        setImgPath('');

        if (onImageLoaded) {
            onImageLoaded(undefined);
        }
    };

    let img: any = '';
    if (type == 'image') {
        img = (
            <div
                onClick={onClickBtnImg}
                className="h-52 w-full cursor-pointer block bg-no-repeat bg-cover flex-grow"
            >
                <img
                    className="object-cover w-full h-full rounded "
                    src={imgPath !== '' ? `${imgPath}` : `${defaultImage}`}
                    alt="image preview"
                />
            </div>
        );
    }
    else if (type == 'video') {
        if (imgPath == '' && defaultImage == DEFAULT_IMAGE_PATH) {
            img = (
                <div
                    onClick={onClickBtnImg}
                    className="h-52 w-full cursor-pointer block flex-grow"
                >
                    <img
                        className="object-cover w-full h-full rounded "
                        src={imgPath !== '' ? `${imgPath}` : `${defaultImage}`}
                        alt="image preview"
                    />
                </div>
            );
        }
        else {
            img = (
                <video
                    src={imgPath !== '' ? `${imgPath}` : `${defaultImage}`}
                    width="100%"
                    height="100%"
                    autoPlay={true}
                    muted={true}
                    loop={true}
                    onClick={onClickBtnImg}
                    className="object-cover w-full h-full rounded shadow-md cursor-pointer"
                >
                    Your browser does not support the video tag.
                </video>
            );
        }
    }

    return (
        <div className="flex flex-row mt-3">
            <input
                type="file"
                hidden
                onChange={(e) => {
                    onInputImgChange(e);
                }}
                ref={inputFile}
                accept={accept}
            />
            <div className="flex flex-col md:flex-row w-full">
                <div className={mergeClasses('w-full md:w-1/4 2xl:w-1/4', imageWrapperClassName)}>{img}</div>
                <div className={mergeClasses('w-full md:w-1/4', buttonWrapperClassName)}>
                    <div className="flex flex-col lg:pl-5 lg:h-full justify-around lg:py-8">
                        <div className="mt-3 md:mt-0 flex flex-col justify-center md:block">
                            <Button type="light-blue" onClick={onClickBtnImg}>
                                {type == 'video' ? t('uploadVideo') : t('uploadImage')}
                            </Button>
                            <div className="text-xs text-gray-400 font-light italic mt-4 lg:mt-2">
                                {uploadText}
                            </div>
                        </div>
                        {imgName &&
                            <div className="flex flex-row items-center justify-between border-b border-gray-400 lg:mt-3">
                                <span className="font-montserrat text-dark-grey-blue-2">{imgName}</span>
                                <XIcon onClick={onClickRemove} className="w-5 h-5 cursor-pointer" />
                            </div>}
                    </div>
                </div>
                {
                    helpText &&
                    <div className="w-full md:w-1/2 h-full text-left md:flex flex-col justify-around help-text">
                        {helpText}
                    </div>
                }
            </div>
        </div>
    );
};
export default FileUpload;
